// const BASE_URL =
//   process.env.NODE_ENV === 'development' ||
//   window.location.hostname === 'localhost' ||
//   window.location.hostname === '127.0.0.1'
//     ? `http://${window.location.hostname}:4200/api`
//     : 'https://findmesales.com/api';

const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  (typeof window !== 'undefined' &&
    (window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'));

const BASE_URL = isDevelopment ? '/api' : 'https://findmesales.com/api';

/*
|-----------------------------------------------------------------------------
| Auth URLs
|-----------------------------------------------------------------------------
*/
export const AUTH_LOGIN_URL = `${BASE_URL}/login`;
export const AUTH_REGISTER_URL = `${BASE_URL}/register`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/password/forgot`;
export const RESET_PASSWORD_URL = `${BASE_URL}/password/reset`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/password/change`;
export const CHAT_GETCHAT_URL = `${BASE_URL}/chat`;
export const CHAT_GETDISTINCT_URL = `${BASE_URL}/chat/distinct`;
export const CHAT_ADDCHAT_URL = `${BASE_URL}/chat/add`;
export const GATHER_SUBMITMESSAGE_URL = `${BASE_URL}/submitmessage`;
export const GATHER_GETPROFILES_URL = `${BASE_URL}/profiles`;
export const GATHER_ADDPROFILES_URL = `${BASE_URL}/profiles/add`;
export const GATHER_PROFILE_STATUS_URL = `${BASE_URL}/profile/status`;
export const GATHER_COUNTPROFILE_URL = `${BASE_URL}/profile/count`;
export const GATHER_INSIGHTS_URL = `${BASE_URL}/insights`;
export const GATHER_ADDINSIGHT_URL = `${BASE_URL}/insights/add`;
export const GATHER_GETLEADS_URL = `${BASE_URL}/leads`;
export const GATHER_GETLEAD_URL = `${BASE_URL}/lead`;
export const GATHER_GETSOCIAL_URL = `${BASE_URL}/social`;
export const GATHER_UPDATELEAD_URL = `${BASE_URL}/lead/update`;
export const GATHER_UKLOCATIONS_URL = `${BASE_URL}/uklocation`;
export const GATHER_WORLDLOCATIONS_URL = `${BASE_URL}/worldlocation`;
export const GATHER_UKBUSINESSES_URL = `${BASE_URL}/ukbusiness`;
export const GATHER_NOTIFICATIONS_URL = `${BASE_URL}/notifications`;
export const GATHER_SUB_NOTIFICATIONS_URL = `${BASE_URL}/subnotifications`;
export const GATHER_NEWNOTIFICATIONS_URL = `${BASE_URL}/notifications/new`;
export const GATHER_ADDNOTIFICATION_URL = `${BASE_URL}/notifications/add`;
export const GATHER_UPDATENOTIFICATION_URL = `${BASE_URL}/notifications/update`;
export const GATHER_DELETENOTIFICATION_URL = `${BASE_URL}/notifications/delete`;
export const GATHER_MARKREADNOTIFICATION_URL = `${BASE_URL}/notifications/markread`;
export const GATHER_COUNTNOTIFICATIONS_URL = `${BASE_URL}/countnotifications`;
export const PROFILE_URL = `${BASE_URL}/profile`;
export const PROFILE_UPDATE_URL = `${BASE_URL}/update`;
export const PROFILE_COMPLETE_URL = `${BASE_URL}/complete`;
export const EMAIL_VERIFY_URL = `${BASE_URL}/verify`;
export const EMAIL_CONFIRM_URL = `${BASE_URL}/confirm`;
export const EMAIL_INBOX_URL = `${BASE_URL}/emails/inbox`;
export const EMAIL_FETCH_URL = `${BASE_URL}/emails/fetch`;
export const EMAIL_SENTBOX_URL = `${BASE_URL}/emails/sentbox`;
export const EMAIL_SEND_URL = `${BASE_URL}/emails/send`;
export const EMAIL_OPTOUT_URL = `${BASE_URL}/emails/optout`;
export const NEWSLETTER_SEND_URL = `${BASE_URL}/newsletter/send`;
export const NEWSLETTER_JOIN_URL = `${BASE_URL}/newsletter/join`;
export const MAILBOX_VERIFY_URL = `${BASE_URL}/mailbox/verify`;
export const MS_VERIFY_URL = `${BASE_URL}/ms/verify`;
export const HTTP_SAVE_TEMPLATE_URL = `${BASE_URL}/template/add`;
export const EMAILS_GETTEMPLATES_URL = `${BASE_URL}/templates`;
export const SETUP_BUSINESS_URL = `${BASE_URL}/business`;
export const SETUP_CUSTOMER_URL = `${BASE_URL}/customer`;
export const SETUP_EMAIL_URL = `${BASE_URL}/email`;
export const ACTIVITY_URL = `${BASE_URL}/activity`;
export const REGION_URL = `${BASE_URL}/region`;
export const CITY_URL = `${BASE_URL}/city`;
export const UKSIC_URL = `${BASE_URL}/uksic`;
export const PRICING_URL = `${BASE_URL}/prices`;
export const STRIPE_CUSTOMER = `${BASE_URL}/create-customer`;
export const STRIPE_SUBSCRIPTION_URL = `${BASE_URL}/create-subscription`;
export const STRIPE_COMPLETE_URL = `${BASE_URL}/complete-subscription`;
export const STRIPE_WEBHOOK = `${BASE_URL}/stripe-webhook`;
export const STRIPE_CONFIRM_URL = `${BASE_URL}/confirm-card-payment`;
export const STRIPE_UPGRADE_URL = `${BASE_URL}/upgrade`;
export const STRIPE_PASTDUE_URL = `${BASE_URL}/past-due`;
export const STRIPE_RETRY_URL = `${BASE_URL}/retry-invoice`;
export const STRIPE_UPDATECARD_URL = `${BASE_URL}/update-card`;
export const STRIPE_GETINVOICE_URL = `${BASE_URL}/get-invoices`;
export const STRIPE_CANCEL_URL = `${BASE_URL}/cancel-subscription`;
export const ADD_DISCOUNT_URL = `${BASE_URL}/add-discount`;
export const CHECK_DISCOUNT_URL = `${BASE_URL}/check-discount`;
export const STRIPE_REACTIVATE_URL = `${BASE_URL}/reactivate-subscription`;
export const STRIPE_RETRIEVE_URL = `${BASE_URL}/retrieve-payment-intent`;
export const STRIPE_DISCOUNT_URL = `${BASE_URL}/retrieve`;
export const COMPANY_UK_QUERY_URL = `${BASE_URL}/company/fetch/uk`;
export const COMPANY_WORLD_QUERY_URL = `${BASE_URL}/company/fetch/world`;
export const COMPANY_UK_STATONAUT_URL = `${BASE_URL}/company/statonaut/uk`;
export const COMPANY_FETCH_ONE_URL = `${BASE_URL}/company/fetch/one`;
export const COMPANY_UK_COUNT_URL = `${BASE_URL}/company/count/uk`;
export const COMPANY_ANALYSE_URL = `${BASE_URL}/company/analyse`;
export const COMPANY_ADD_URL = `${BASE_URL}/company/add`;
export const COMPANY_DIRECTORS_URL = `${BASE_URL}/company/directors`;
export const COMPANY_WEBSITE_URL = `${BASE_URL}/company/website`;
export const COMPANY_FETCH_LEADS_URL = `${BASE_URL}/company/fetch/leads`;
export const UL_FETCH_ONE_URL = `${BASE_URL}/ul/fetch/one`;
export const GL_FETCH_ONE_URL = `${BASE_URL}/gl/fetch/one`;
export const PEOPLE_FETCH_PEOPLE_URL = `${BASE_URL}/people/fetch/people`;
export const HTTP_ADD_ONE_PERSON_URL = `${BASE_URL}/people/add/one`;
export const HTTP_FIND_ONE_PERSON_URL = `${BASE_URL}/people/find/one`;
export const HTTP_EDIT_ONE_PERSON_URL = `${BASE_URL}/people/edit/one`;
export const PEOPLE_QUERY_URL = `${BASE_URL}/people/fetch/query`;
export const HTTP_SAVE_FLOW_URL = `${BASE_URL}/flow/add`;
export const HTTP_GET_FLOW_URL = `${BASE_URL}/flow`;
export const HTTP_UPDATE_FLOW_URL = `${BASE_URL}/flow/update`;
export const HTTP_SAVE_CAMPAIGN_URL = `${BASE_URL}/campaign/add`;
export const HTTP_UPDATE_CAMPAIGN_URL = `${BASE_URL}/campaign/update`;
export const HTTP_GET_CAMPAIGN_URL = `${BASE_URL}/campaign`;
export const HTTP_GET_ACTIONS_URL = `${BASE_URL}/actions`;
export const HTTP_GET_USERDATA_URL = `${BASE_URL}/userlead/fetch`;
export const HTTP_GET_USERDATAMANY_URL = `${BASE_URL}/userlead/many`;
export const HTTP_UPDATE_USERDATA_URL = `${BASE_URL}/userlead/update`;
export const HTTP_NEW_USERDATA_URL = `${BASE_URL}/userlead/new`;
export const HTTP_ADD_USERDATA_URL = `${BASE_URL}/userlead/add`;
export const HTTP_ADD_ONE_USERDATA_URL = `${BASE_URL}/userlead/add/one`;
export const HTTP_SEARCH_USERDATA_URL = `${BASE_URL}/userlead/search`;
export const HTTP_USERTAGS_USERDATA_URL = `${BASE_URL}/userlead/addtag`;

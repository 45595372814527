import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import {REGISTER_ACTION, CLEAR_AUTH_ACTION} from 'app/actions/authAction';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import {HTTP_REQUEST_EMAIL_VERIFY} from 'app/services/httpClient/auth.service';
import {HTTP_COMPLETE_PROFILE} from 'app/services/httpClient/profile.service';
import withRouter from 'app/utils/withRouter';
import HelmetMaker from 'app/utils/Helmet';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import ReactGA from 'react-ga4';

import moment from 'moment';
import {toast} from 'react-toastify';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

// ------- MUI imports for styling like your second snippet -------
import {Box, Button, CssBaseline, Divider, Grid, Typography, IconButton, Link} from '@mui/material';

import {CreditCardOff as CreditCardOffIcon, TaskAlt as TaskAltIcon} from '@mui/icons-material';

// ------- Redux State Mappings -------
function mapStateToProps(state) {
  return {
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_REGISTER: (payload) => dispatch(REGISTER_ACTION(payload)),
    DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
  };
}

class CompleteSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameerror: false,
      Payload: {
        firstName: null,
        lastName: null,
        fullName: null,
        nameerror: false,
        businessName: null,
        jobTitle: null,
        agreeTerms: false,
        email: null,
        phone: null,
        password: null,
        confirm_password: null,
        Payload: {},
        sub_id: 'undefined',
        sub_created: 'undefined',
        current_period_end: 'undefined',
        current_period_start: 'undefined',
        stripeCustomerId: 'undefined',
        cancel_at: 'undefined',
        cancel_at_period_end: false,
        canceled_at: 'undefined',
        paymentMethod_id: 'undefined',
        paymentMethod_brand: 'undefined',
        last4: 'undefined',
        exp_month: 'undefined',
        status: 'undefined',
        max_profiles: 1,
        exp_year: 'undefined',
        nameOnCard: 'undefined',
        billingAddress: 'undefined',
        city: 'undefined',
        postCodeZip: 'undefined',
        country: 'undefined',
        subscription: 'free',
        mailbox: {
          user: null,
          password: {},
          outgoinghost: null,
          incominghost: null,
        },
      },
      questionsLeft: this.calculateQuestionsLeft(props.GET_PROFILE_STATE, {}),
      showPassword: false,
    };

    this.HandleInputChange = this.HandleInputChange.bind(this);
    this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.calculateQuestionsLeft = this.calculateQuestionsLeft.bind(this);
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  // -----------------------------------------------------------------
  async componentDidMount() {
    const {GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, DISPATCH_PROFILE, navigate} =
      this.props;

    // Fetch profile if authenticated
    await DISPATCH_PROFILE(GET_AUTH_STATE.token);

    // Update questions left
    this.setState({
      questionsLeft: this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload),
    });

    // If user is authenticated, force them to remain on /complete-signup
    const {isAuthenticated, token} = GET_AUTH_STATE;
    if (isAuthenticated && token !== null) {
      navigate('/complete-signup');
    }

    // Display errors if present
    if (GET_ERRORS_STATE.length !== 0) {
      toast.error(GET_ERRORS_STATE);
      DISPATCH_CLEAR_AUTH();
    }
  }

  componentDidUpdate(prevProps) {
    const {GET_ERRORS_STATE, GET_AUTH_STATE, GET_PROFILE_STATE} = this.props;
    // If authentication status changes, redirect to success
    if (
      GET_AUTH_STATE &&
      GET_AUTH_STATE.isAuthenticated !== prevProps.GET_AUTH_STATE?.isAuthenticated
    ) {
      this.props.navigate('/success/?m=free-trial');
    }

    // If profile changes, recalculate questions left
    if (GET_PROFILE_STATE !== prevProps.GET_PROFILE_STATE) {
      const newQuestionsLeft = this.calculateQuestionsLeft(GET_PROFILE_STATE, this.state.Payload);
      if (this.state.questionsLeft !== newQuestionsLeft) {
        this.setState({questionsLeft: newQuestionsLeft});
      }
    }
  }

  // -----------------------------------------------------------------
  // Handlers
  // -----------------------------------------------------------------

  handleTogglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  HandleInputChange(event) {
    const date = moment().unix();
    const future = moment().add(1, 'M').unix();

    // Real-time update of questionsLeft
    const newQuestionsLeft = this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, {
      ...this.state.Payload,
      [event.target.name]: event.target.value,
    });

    this.setState((prevState) => ({
      nameerror: false,
      Payload: {
        ...prevState.Payload,
        [event.target.name]: event.target.value,
        current_period_start: date,
        current_period_end: future,
      },
      questionsLeft: newQuestionsLeft,
    }));
  }

  HandleCheckboxChange() {
    toast.dismiss();
    this.setState((prevState) => ({
      Payload: {
        ...prevState.Payload,
        agreeTerms: !prevState.Payload.agreeTerms,
      },
    }));
  }

  async HandleSubmit(event) {
    event.preventDefault();

    let firstName = '';
    let lastName = '';
    let all;
    if (this.state.Payload.fullName) {
      const name = this.state.Payload.fullName.trim();
      all = name.split(' ').length;

      const first = name.split(' ')[0];
      const last = name.substr(name.indexOf(' ') + 1);
      firstName = this.capitalize(first);
      lastName = this.capitalize(last);
    }

    // Must contain at least two words
    if (all < 2) {
      this.setState({nameerror: true});
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 60);
      }
      toast.error('Please enter your full name');
      return;
    }

    const profile = this.props.GET_PROFILE_STATE;
    const payload = {
      _id: profile._id,
      email: profile.email,
      firstName: profile.firstName ? profile.firstName : firstName,
      lastName: profile.lastName ? profile.lastName : lastName,
      businessName: this.state.Payload.businessName,
    };
    if (this.state.Payload.password) {
      payload.password = this.state.Payload.password;
    }

    const {GET_AUTH_STATE, DISPATCH_PROFILE} = this.props;
    HTTP_COMPLETE_PROFILE(payload)
      .then((response) => response.json())
      .then((response) => {
        if (response.length !== 0) {
          toast.success('Thank you for completing registration.');
          this.props.navigate('/companies');
          DISPATCH_PROFILE(GET_AUTH_STATE.token);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }

  // -----------------------------------------------------------------
  // Utility
  // -----------------------------------------------------------------
  calculateQuestionsLeft(profileState, payloadState = {}) {
    let count = 0;

    // Check Full Name
    if (!profileState.firstName && !payloadState.fullName) {
      count += 1;
    }
    // Check Business Name
    if (!profileState.businessName && !payloadState.businessName) {
      count += 1;
    }
    // Check Password
    if (!profileState.password && !payloadState.password) {
      count += 1;
    }
    return count;
  }

  capitalize(text) {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  // -----------------------------------------------------------------
  // Render
  // -----------------------------------------------------------------
  render() {
    const {questionsLeft} = this.state;
    const {t: lang} = this.props;

    const helmetload = {
      title: 'Complete Signup | Find Me Sales',
      description:
        'Complete your sign up for your free Find Me Sales trial of 10 free credits in a month.',
      url: 'https://findmesales.com/complete',
      keywords: 'Leads, Sales',
    };

    let subtitle;
    if (questionsLeft > 1) {
      subtitle = `You're only ${questionsLeft} answers away from finding your first sales`;
    } else if (questionsLeft === 1) {
      subtitle = "You're only 1 answer away from finding your first sales";
    } else {
      subtitle = "You're only 1 button click away from finding your first sales";
    }

    return (
      <Fragment>
        <HelmetMaker props={helmetload} />
        <CssBaseline />

        {/* Outer wrapper for background, centered content */}
        <Box
          sx={{
            maxHeight: '100vh',
            background: '#fafafa',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '60px 20px',
          }}>
          {/* White card container */}
          <Box
            sx={{
              maxWidth: '640px',
              minHeight: '664px',
              width: '100%',
              bgcolor: '#fff',
              marginTop: '38px',
              boxShadow: '0 8px 60px rgba(0, 0, 0, 0.15)',
              borderRadius: 4,
              p: {xs: 2, sm: 4},
              textAlign: 'center',
            }}>
            {/* Header */}
            {questionsLeft >= 0 && (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '34px !important',
                  marginBottom: '48px',
                  color: '#000',
                  lineHeight: '1.4',
                  letterSpacing: '-1px',
                }}>
                {subtitle}
              </Typography>
            )}

            {/* Conditional Subtitle */}
            {/* {questionsLeft >= 0 && (
              <Typography
                component="h2"
                variant="h6"
                sx={{
                  paddingBottom: '24px',
                  color: '#444',
                  textAlign: 'center', // Center the subtitle
                  maxWidth: '386px', // Reduced max width for subtitle
                  margin: '0 auto', // Center the subtitle
                  lineHeight: '1.4 !important',
                  letterSpacing: '-1px !important',
                }}>
                {subtitle}
              </Typography>
            )} */}

            {/* The form */}
            <ValidatorForm
              id="regform"
              onSubmit={this.HandleSubmit}
              onError={(errors) => {
                if (typeof window !== 'undefined') {
                  window.scrollTo(0, 0);
                  toast.error(errors);
                }
              }}>
              <Grid container spacing={2} justifyContent="center">
                {/* Full Name if not available in profile */}
                {!this.props.GET_PROFILE_STATE.firstName && (
                  <Grid item xs={12}>
                    <TextValidator
                      name="fullName"
                      value={this.state.Payload.fullName || ''}
                      variant="outlined"
                      onChange={this.HandleInputChange}
                      error={this.state.nameerror}
                      fullWidth
                      autoFocus
                      label="Full Name"
                      validators={['required']}
                      errorMessages={['This field is required']}
                      InputLabelProps={{
                        // shrink: true,
                        style: {paddingLeft: '16px'},
                      }}
                      inputProps={{
                        style: {paddingLeft: '12px !important'},
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderRadius: '46px',
                          backgroundColor: '#fff',
                          height: '70px',
                          paddingLeft: '12px',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: '16px',
                          marginTop: '-4px',
                          backgroundColor: 'white',
                          borderRadius: '25px',
                        },
                        '& .MuiFormHelperText-root': {
                          zIndex: 2,
                          // marginTop: '-11px',
                          paddingLeft: '14px',
                        },
                        '& .MuiFormLabel-root': {
                          paddingTop: '10px !important',
                        },
                      }}
                    />
                  </Grid>
                )}

                {/* Business Name */}
                <Grid item xs={12}>
                  <TextValidator
                    // autoComplete="businessName"
                    name="businessName"
                    value={this.state.Payload.businessName || ''}
                    variant="outlined"
                    onChange={this.HandleInputChange}
                    fullWidth
                    label="Business Name"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    InputLabelProps={{
                      // shrink: true,
                      style: {paddingLeft: '16px'},
                    }}
                    inputProps={{
                      style: {paddingLeft: '12px !important'},
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        borderRadius: '46px',
                        backgroundColor: '#fff',
                        height: '70px',
                        paddingLeft: '12px',
                      },
                      '& .MuiInputLabel-shrink': {
                        paddingRight: '16px',
                        marginTop: '-4px',
                        backgroundColor: 'white',
                        borderRadius: '25px',
                      },
                      '& .MuiFormHelperText-root': {
                        zIndex: 2,
                        // marginTop: '-11px',
                        paddingLeft: '14px',
                      },
                      '& .MuiFormLabel-root': {
                        paddingTop: '10px !important',
                      },
                    }}
                  />
                </Grid>

                {/* Password if not set in profile */}
                {!this.props.GET_PROFILE_STATE.password && (
                  <Grid item xs={12} style={{minHeight: '124px', position: 'relative'}}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      onChange={this.HandleInputChange}
                      name="password"
                      label="Choose password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.Payload.password || ''}
                      validators={['required', 'minStringLength:8']}
                      errorMessages={['This field is required', '8 Characters Minimum']}
                      InputLabelProps={{
                        style: {paddingLeft: '16px'},
                      }}
                      inputProps={{
                        style: {paddingLeft: '12px'},
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderRadius: '46px',
                          backgroundColor: '#fff',
                          height: '70px',
                          paddingLeft: '12px',
                          paddingRight: '50px', // Space for eye icon
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: '16px',
                          marginTop: '-4px',
                          backgroundColor: 'white',
                          borderRadius: '25px',
                        },
                        '& .MuiFormHelperText-root': {
                          zIndex: 2,
                          paddingLeft: '14px',
                        },
                        '& .MuiFormLabel-root': {
                          paddingTop: '10px !important',
                          paddingLeft: '14px',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={this.handleTogglePasswordVisibility}
                            sx={{
                              position: 'absolute',
                              right: '14px', // Proper spacing
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 2, // Keeps it on top
                              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Blends in
                              borderRadius: '50%',
                              padding: '6px',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              },
                            }}>
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                )}

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    form="regform"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: '46px',
                      textTransform: 'none',
                      fontWeight: 500,
                      fontSize: '24px',
                      boxShadow: 'none',
                      padding: '14px 24px',
                      '&:hover': {
                        backgroundColor: '#00285a',
                      },
                    }}>
                    Complete Signup
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>

            <Box
              sx={{
                mt: '48px',
                fontSize: '12px',
                lineHeight: 1.4,
                color: '#666',
              }}>
              By clicking &ldquo;Complete Signup&rdquo; you agree to our{' '}
              <Link
                target="_blank"
                href="/terms"
                variant="body2"
                sx={{color: '#003671', fontSize: '12px !important'}}>
                Terms
              </Link>{' '}
              and{' '}
              <Link
                target="_blank"
                href="/privacy"
                variant="body2"
                sx={{color: '#003671', fontSize: '12px !important'}}>
                Privacy Policy
              </Link>
            </Box>
            {/* Bottom text: "No card needed" + Terms/Privacy */}
            <Box sx={{mt: '66px', color: '#666', fontSize: '14px'}}>
              <CreditCardOffIcon
                sx={{
                  verticalAlign: 'middle',
                  mb: '2px',
                  fontSize: '18px',
                }}
              />{' '}
              No card required{' '}
              <span style={{padding: '0px 24px', color: 'rgb(234, 224, 235)'}}>&nbsp;|&nbsp;</span>
              <TaskAltIcon
                sx={{
                  verticalAlign: 'middle',
                  mb: '2px',
                  fontSize: '18px',
                }}
              />{' '}
              Data compliant
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  }
}

CompleteSignup.propTypes = {
  DISPATCH_REGISTER: PropTypes.func.isRequired,
  DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
  GET_ERRORS_STATE: PropTypes.array.isRequired,
  GET_AUTH_STATE: PropTypes.object.isRequired,
  GET_PROFILE_STATE: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

// ------- Export with Redux and Router -------
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompleteSignup));

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, {Component, Fragment} from 'react';
// import PropTypes from 'prop-types';
// import {connect} from 'react-redux';
// import {withNamespaces} from 'react-i18next';
// import {REGISTER_ACTION, CLEAR_AUTH_ACTION} from 'app/actions/authAction';
// import {PROFILE_ACTION} from 'app/actions/profileAction';
// import {HTTP_REQUEST_EMAIL_VERIFY} from 'app/services/httpClient/auth.service';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import Caps from 'app/common/caps';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import {HTTP_COMPLETE_PROFILE} from 'app/services/httpClient/profile.service';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Hidden from '@mui/material/Hidden';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import HelmetMaker from 'app/utils/Helmet';
// import ReactGA from 'react-ga4';
// import Container from '@mui/material/Container';
// import Divider from '@mui/material/Divider';
// import {toast} from 'react-toastify';
// import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
// import withRouter from 'app/utils/withRouter';

// const moment = require('moment');

// function mapStateToProps(state) {
//   return {
//     GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
//     GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
//     GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     DISPATCH_REGISTER: (payload) => dispatch(REGISTER_ACTION(payload)),
//     DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
//     DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
//   };
// }

// class CompleteSignup extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       nameerror: false,
//       Payload: {
//         firstName: null,
//         lastName: null,
//         fullName: null,
//         nameerror: false,
//         businessName: null,
//         jobTitle: null,
//         agreeTerms: false,
//         email: null,
//         phone: null,
//         password: null,
//         confirm_password: null,
//         Payload: {},
//         sub_id: 'undefined',
//         sub_created: 'undefined',
//         current_period_end: 'undefined',
//         current_period_start: 'undefined',
//         stripeCustomerId: 'undefined',
//         cancel_at: 'undefined',
//         cancel_at_period_end: false,
//         canceled_at: 'undefined',
//         paymentMethod_id: 'undefined',
//         paymentMethod_brand: 'undefined',
//         last4: 'undefined',
//         exp_month: 'undefined',
//         status: 'undefined',
//         max_profiles: 1,
//         exp_year: 'undefined',
//         nameOnCard: 'undefined',
//         billingAddress: 'undefined',
//         city: 'undefined',
//         postCodeZip: 'undefined',
//         country: 'undefined',
//         subscription: 'free',
//         mailbox: {
//           user: null,
//           password: {},
//           outgoinghost: null,
//           incominghost: null,
//         },
//       },
//       questionsLeft: this.calculateQuestionsLeft(props.GET_PROFILE_STATE, {}),
//     };
//     // bind event handlers in class components.
//     this.HandleInputChange = this.HandleInputChange.bind(this);
//     this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
//     this.HandleSubmit = this.HandleSubmit.bind(this);
//     this.calculateQuestionsLeft = this.calculateQuestionsLeft.bind(this);
//   }

//   async componentDidMount() {
//     const {GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, DISPATCH_PROFILE, navigate} =
//       this.props;
//     await DISPATCH_PROFILE(GET_AUTH_STATE.token);

//     // console.log('COMPLETE CDM', this.props.GET_PROFILE_STATE);
//     this.setState({
//       questionsLeft: this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload),
//     });
//     // console.log('COMPLETE CDM', this.props.GET_PROFILE_STATE);
//     const {isAuthenticated, token} = GET_AUTH_STATE;
//     if (isAuthenticated && token !== null) {
//       navigate('/complete-signup');
//     }
//     if (GET_ERRORS_STATE.length !== 0) {
//       toast.error(GET_ERRORS_STATE);
//       DISPATCH_CLEAR_AUTH();
//     }
//   }

//   componentDidUpdate(prevState, prevProps) {
//     const {GET_ERRORS_STATE, GET_AUTH_STATE} = this.props;
//     if (
//       GET_AUTH_STATE &&
//       GET_AUTH_STATE.isAuthenticated !== prevState.GET_AUTH_STATE.isAuthenticated
//     ) {
//       this.props.navigate('/success/?m=free-trial');
//     }
//     if (this.props.GET_PROFILE_STATE !== prevProps.GET_PROFILE_STATE) {
//       const newQuestionsLeft = this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload);
//       if (this.state.questionsLeft !== newQuestionsLeft) {
//         this.setState({
//           questionsLeft: newQuestionsLeft,
//         });
//       }
//     }
//   }

//   HandleInputChange(event) {
//     const date = moment().unix();
//     const future = moment().add(1, 'M').unix();
//     this.setState((prevState) => ({
//       nameerror: false,
//       Payload: {
//         ...this.state.Payload,
//         [event.target.name]: event.target.value,
//         current_period_start: date,
//         current_period_end: future,
//         questionsLeft: this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload),
//       },
//     }));
//     const newQuestionsLeft = this.calculateQuestionsLeft(this.props.GET_PROFILE_STATE, this.state.Payload);

//     // Update the state only if questionsLeft actually changes
//     if (this.state.questionsLeft !== newQuestionsLeft) {
//       this.setState((prevState) => ({
//         nameerror: false,
//         Payload: {
//           ...this.state.Payload,
//           [event.target.name]: event.target.value,
//           current_period_start: date,
//           current_period_end: future,
//         },
//         questionsLeft: newQuestionsLeft,
//       }));
//     } else {
//       // Only update the Payload part of the state
//       this.setState((prevState) => ({
//         nameerror: false,
//         Payload: {
//           ...this.state.Payload,
//           [event.target.name]: event.target.value,
//           current_period_start: date,
//           current_period_end: future,
//         },
//       }));
//     }
//   }

//   calculateQuestionsLeft(profileState, payloadState = {}) {
//     let count = 0;

//     // Check for fullName
//     if (!profileState.firstName && !payloadState.fullName) {
//       count += 1;
//     }

//     // Check for businessName
//     if (!profileState.businessName && !payloadState.businessName) {
//       count += 1;
//     }

//     // Check for password
//     if (!profileState.password && !payloadState.password) {
//       count += 1;
//     }

//     return count;
//   }

//   HandleCheckboxChange(event) {
//     toast.dismiss();
//     if (this.state.Payload.agreeTerms === false) {
//       this.setState({
//         Payload: {
//           ...this.state.Payload,
//           agreeTerms: true,
//         },
//       });
//     } else {
//       this.setState({
//         Payload: {
//           ...this.state.Payload,
//           agreeTerms: false,
//         },
//       });
//     }
//   }

//   async HandleSubmit(event) {
//     event.preventDefault();
//     // if (this.state.Payload.agreeTerms === false) {
//     //   toast.error('You must agree with our Terms and Conditions to register.');
//     // } else {

//     let firstName = '';
//     let lastName = '';
//     let all;
//     if (this.state.Payload.fullName) {
//       const name = this.state.Payload.fullName.trim();
//       all = name.split(' ').length;
//       // const name = req.body.fullName;
//       const first = name.split(' ')[0];
//       const last = name.substr(name.indexOf(' ') + 1);
//       firstName = Caps(first);
//       lastName = Caps(last);
//     }
//     if (all < 2) {
//       // setLoading(false);
//       // setNameError(true);
//       this.setState({
//         nameerror: true,
//       });
//       if (typeof window !== 'undefined') {
//         window.scrollTo(0, 60);
//       }
//       toast.error('Please enter your full name');
//     } else {
//       const profile = this.props.GET_PROFILE_STATE;
//       const payload = {
//         _id: profile._id,
//         email: profile.email,
//         firstName: profile.firstName ? profile.firstName : firstName,
//         lastName: profile.lastName ? profile.lastName : lastName,
//         businessName: this.state.Payload.businessName,
//       };
//       if (this.state.Payload.password) {
//         payload.password = this.state.Payload.password;
//       }
//       // console.log('SUBMIT', payload, profile);
//       const {GET_AUTH_STATE, DISPATCH_PROFILE} = this.props;
//       HTTP_COMPLETE_PROFILE(payload)
//         .then((response) => response.json())
//         .then((response) => {
//           const reslength = response.length;
//           if (reslength !== 0) {
//             toast.success('Thank you for completing registration.');
//             this.props.navigate('/companies');
//             DISPATCH_PROFILE(GET_AUTH_STATE.token);
//           }
//           return response;
//         })
//         .catch((error) => {
//           // handle error
//           console.log(error);
//           return;
//         });
//     }
//   }

//   render() {
//     const {questionsLeft} = this.state;
//     const {t: lang, navigate} = this.props;
//     const helmetload = {
//       title: 'Complete Signup | Find Me Sales',
//       description:
//         'Complete your sign up for your free Find Me Sales trial of 10 free credits in a month.',
//       url: 'https://findmesales.com/complete',
//       // image:
//       //   'https://storage.cloud.google.com/find-me-sales-bucket/assets/leadsearchhome.jpg',
//       keywords: 'Leads, Sales',
//     };
//     let subtitle;
//     if (questionsLeft > 1) {
//       subtitle = `You're only ${questionsLeft} answers away from finding your first sales`;
//     } else if (questionsLeft === 1) {
//       subtitle = "You're only 1 answer away from finding your first sales";
//     } else {
//       subtitle = "You're only 1 button click away from finding your first sales";
//     }
//     return (
//       <Fragment>
//         <HelmetMaker props={helmetload} />
//         <Container
//           // className="register-container"
//           component="main"
//           maxWidth="sm"
//           style={{paddingBottom: '200px', minWidth: '97%'}}>
//           <div className="regpaper">
//             <Grid container>
//               <Grid
//                 container
//                 xs={12}
//                 // md={6}
//                 direction="column"
//                 alignItems="center"
//                 justifyContent="center"
//                 style={{padding: '0px 5%'}}>
//                 <Grid item style={{maxWidth: '480px'}}>
//                   <ValidatorForm
//                     id="regform"
//                     onError={(errors) => {
//                       if (typeof window !== 'undefined') {
//                         window.scrollTo(0, 260), toast.error(errors);
//                       }
//                     }}
//                     onSubmit={this.HandleSubmit}>
//                     <CssBaseline />
//                     <Typography
//                       className="registerheader"
//                       component="h1"
//                       variant="h1"
//                       style={{fontWeight: '400', paddingBottom: '24px'}}>
//                       Complete Signup
//                     </Typography>
//                     {questionsLeft >= 0 && (
//                       <Typography component="h2" variant="h6" style={{paddingBottom: '24px'}}>
//                         {subtitle}
//                       </Typography>
//                     )}
//                     <Grid container spacing={2}>
//                       {!this.props.GET_PROFILE_STATE.firstName ? (
//                         <Grid item xs={12}>
//                           <TextValidator
//                             // autoComplete="fullName"
//                             name="fullName"
//                             value={this.state.Payload.fullName}
//                             variant="outlined"
//                             onChange={this.HandleInputChange}
//                             error={this.state.nameerror}
//                             fullWidth
//                             autoFocus
//                             id="firstName"
//                             label="Full Name"
//                             validators={['required']}
//                             errorMessages={['This field is required']}
//                           />
//                         </Grid>
//                       ) : null}
//                       <Grid item xs={12}>
//                         <TextValidator
//                           autoComplete="businessName"
//                           name="businessName"
//                           value={this.state.Payload.businessName}
//                           variant="outlined"
//                           onChange={this.HandleInputChange}
//                           fullWidth
//                           id="businessName"
//                           label="Business Name"
//                           validators={['required']}
//                           errorMessages={['This field is required']}
//                         />
//                       </Grid>
//                       {!this.props.GET_PROFILE_STATE.password ? (
//                         <Grid item xs={12}>
//                           <TextValidator
//                             variant="outlined"
//                             fullWidth
//                             onChange={this.HandleInputChange}
//                             name="password"
//                             label="Choose password"
//                             type="password"
//                             id="password"
//                             value={this.state.Payload.password}
//                             // autoComplete="current-password"
//                             validators={['required', 'minStringLength:8']}
//                             errorMessages={['This field is required', '8 Characters Minimum']}
//                           />
//                         </Grid>
//                       ) : null}
//                     </Grid>
//                     <Button
//                       type="submit"
//                       form="regform"
//                       fullWidth
//                       variant="contained"
//                       color="primary"
//                       className="registersubmit">
//                       Complete Signup
//                     </Button>
//                     <Grid container justifyContent="center" style={{textAlign: 'center'}}>
//                       <Grid item xs={12}>
//                         <Typography
//                           variant="body2"
//                           paragraph
//                           align="justify"
//                           style={{textAlign: 'center'}}>
//                           No card required
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={12}>
//                         <Typography
//                           variant="body2"
//                           paragraph
//                           align="justify"
//                           style={{
//                             textAlign: 'center',
//                             fontSize: '12px',
//                             padding: '0px',
//                           }}>
//                           By clicking 'Complete Signup' you agree to our{' '}
//                           <Link
//                             target="_blank"
//                             href="/terms"
//                             variant="body1"
//                             align="left"
//                             style={{fontSize: '12px'}}
//                             className="pagelink">
//                             Terms
//                           </Link>{' '}
//                           and{' '}
//                           <Link
//                             target="_blank"
//                             href="/privacy"
//                             variant="body1"
//                             style={{fontSize: '12px'}}
//                             align="left"
//                             className="pagelink">
//                             Privacy Policy
//                           </Link>
//                         </Typography>
//                       </Grid>
//                       {/* <Grid item xs={12} style={{textAlign: 'center'}}>
//                         <Link
//                           style={{cursor: 'pointer', fontSize: '12px'}}
//                           onClick={() => {
//                             this.props.navigate('login');
//                           }}
//                           variant="body2">
//                           Already have an account? Sign in
//                         </Link>
//                       </Grid> */}
//                     </Grid>
//                   </ValidatorForm>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </div>
//         </Container>
//       </Fragment>
//     );
//   }
// }

// CompleteSignup.propTypes = {
//   DISPATCH_REGISTER: PropTypes.func.isRequired,
//   DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
//   GET_ERRORS_STATE: PropTypes.array.isRequired,
//   GET_AUTH_STATE: PropTypes.object.isRequired,
//   // t: PropTypes.func.isRequired,
//   navigate: PropTypes.func.isRequired,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompleteSignup));

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React, {useEffect, useState, memo} from 'react';

// import CacheBuster from 'react-cache-buster';
import {Routes, Route, useParams, useLocation, Navigate} from 'react-router-dom';
import loadable from '@loadable/component';
import {useSelector} from 'react-redux';
// import {PROFILE_ACTION} from 'app/actions/profileAction';
import Grid from '@mui/material/Grid';
import {ToastContainer} from 'react-toastify';
import {ThemeProvider, StyledEngineProvider, responsiveFontSizes} from '@mui/material/styles';
import {createTheme} from '@mui/material';
import {loadStripe} from '@stripe/stripe-js/pure';
import Hidden from '@mui/material/Hidden';
import {Elements} from '@stripe/react-stripe-js';
import Fab from '@mui/material/Fab';
import ReactGA from 'react-ga4';
// import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import InfoTooltip from 'app/components/infotooltip';
import ScrollToTop from 'app/components/scrollToTop';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Messenger from 'app/layouts/messenger';
import AddAction from 'app/layouts/addaction';
import {
  Root,
  Header,
  EdgeSidebar,
  SidebarContent,
  EdgeTrigger,
  Content,
  Footer,
} from '@mui-treasury/layout';
import CssBaseline from '@mui/material/CssBaseline';
import {ParallaxProvider} from 'react-scroll-parallax';
import Nav from 'app/layouts/nav';
import PrivateRoute from 'app/guards';
import LoginRedirect from 'app/guards/loginredirect';
import Button from '@mui/material/Button';
// import GA from 'app/utils/GoogleAnalytics';
import GA4 from 'app/utils/GoogleAnalytics4';
import {DndProvider} from 'react-dnd';
import CacheBuster from 'app/utils/CacheBuster';
import {HTML5Backend} from 'react-dnd-html5-backend';
import CompleteSignup from 'app/pages/auth/completesignup';
import Confirm from 'app/pages/auth/confirm';

if (typeof window !== 'undefined') {
  require('react-toastify/dist/ReactToastify.css');
}

const Welcome = loadable(() => import(/* webpackChunkName: "Welcome" */ 'app/pages/welcome'));
const VerifyingPayment = loadable(
  () => import(/* webpackChunkName: "VerifyingPayment" */ 'app/components/verifyingPayment')
);
const LearnPanel = loadable(
  () => import(/* webpackChunkName: "LearnPanel" */ 'app/components/learn/learn-panel')
);
const SideNavBar = loadable(
  () => import(/* webpackChunkName: "sidenavbar" */ 'app/layouts/sidenavbar')
);
const Copyright = loadable(
  () => import(/* webpackChunkName: "copyright" */ 'app/components/copyright')
);
const Newsletter = loadable(
  () => import(/* webpackChunkName: "newsletter" */ 'app/layouts/newsletter')
);
const Report = loadable(() => import(/* webpackChunkName: "report" */ 'app/pages/reports/report'));
// import Dashboard from 'app/pages/dashboard/Dashboard';
const Compose = loadable(() => import(/* webpackChunkName: "Compose" */ 'app/pages/emails/Compose'));
const Product = loadable(() => import(/* webpackChunkName: "Product" */ 'app/pages/product'));
const Solutions = loadable(() => import(/* webpackChunkName: "Solutions" */ 'app/pages/solutions'));
const Features = loadable(() => import(/* webpackChunkName: "Features" */ 'app/pages/features'));
const Technology = loadable(
  () => import(/* webpackChunkName: "Technology" */ 'app/pages/technology')
);
const Inspiration = loadable(
  () => import(/* webpackChunkName: "Inspiration" */ 'app/pages/inspiration')
);
const Insights = loadable(() => import(/* webpackChunkName: "Insights" */ 'app/pages/insights'));
const Pricing = loadable(() => import(/* webpackChunkName: "Pricing" */ 'app/pages/pricing'));
const Upgrade = loadable(() => import(/* webpackChunkName: "Upgrade" */ 'app/pages/upgrade'));
const Login = loadable(() => import(/* webpackChunkName: "Login" */ 'app/pages/auth/login'));
const Success = loadable(() => import(/* webpackChunkName: "Login" */ 'app/pages/auth/success'));
const Register = loadable(
  () => import(/* webpackChunkName: "Register" */ 'app/pages/auth/register')
);

const Forgot = loadable(
  () => import(/* webpackChunkName: "Forgot" */ 'app/pages/auth/passwords/forgot')
);
const Reset = loadable(
  () => import(/* webpackChunkName: "Reset" */ 'app/pages/auth/passwords/reset')
);
const Preconfirm = loadable(
  () => import(/* webpackChunkName: "Preconfirm" */ 'app/pages/auth/preconfirm')
);
// const Confirm = loadable(() => import(/* webpackChunkName: "Confirm" */ 'app/pages/auth/confirm'));

const Priya = loadable(() => import(/* webpackChunkName: "Priya" */ 'app/pages/stories/priya'));
const James = loadable(() => import(/* webpackChunkName: "James" */ 'app/pages/stories/james'));
const Alison = loadable(() => import(/* webpackChunkName: "Alison" */ 'app/pages/stories/alison'));
// const Setup = loadable(() => import(/* webpackChunkName: "Setup" */ 'app/pages/setup/setup'));
const Quarters = loadable(() => import(/* webpackChunkName: "Quarters" */ 'app/pages/quarters'));
const OurStory = loadable(
  () => import(/* webpackChunkName: "OurStory" */ 'app/pages/frontend/ourstory')
);
const Support = loadable(
  () => import(/* webpackChunkName: "Support" */ 'app/pages/frontend/support')
);
const Livechat = loadable(() => import(/* webpackChunkName: "Livechat" */ 'app/pages/livechat'));
const Notifications = loadable(
  () => import(/* webpackChunkName: "Notifications" */ 'app/pages/notifications/notifications')
);
const Leads = loadable(() => import(/* webpackChunkName: "Leads" */ 'app/pages/leads/Leads'));
const People = loadable(() => import(/* webpackChunkName: "People" */ 'app/pages/people/people'));

const Person = loadable(() => import(/* webpackChunkName: "Person" */ 'app/pages/person/person'));
const Lead = loadable(() => import(/* webpackChunkName: "Lead" */ 'app/pages/lead/lead'));
const Leadsearch = loadable(
  () => import(/* webpackChunkName: "leadsearch" */ 'app/pages/leadsearch/leadsearch')
);
const Actions = loadable(
  () => import(/* webpackChunkName: "actions" */ 'app/pages/actions/actions')
);
const Social = loadable(() => import(/* webpackChunkName: "Social" */ 'app/pages/social/social'));
const Emails = loadable(() => import(/* webpackChunkName: "Emails" */ 'app/pages/emails/emails'));
const View = loadable(() => import(/* webpackChunkName: "View" */ 'app/pages/emails/view'));
const Profiles = loadable(
  () => import(/* webpackChunkName: "Profiles" */ 'app/pages/profiles/profiles')
);
const Templates = loadable(
  () => import(/* webpackChunkName: "Templates" */ 'app/pages/templates/templates')
);
const Campaigns = loadable(
  () => import(/* webpackChunkName: "Campaigns" */ 'app/pages/campaigns/campaigns')
);
const Automation = loadable(
  () => import(/* webpackChunkName: "Automation" */ 'app/pages/automation/automation')
);
const Settings = loadable(() => import(/* webpackChunkName: "Settings" */ 'app/pages/settings'));
const Preferences = loadable(
  () => import(/* webpackChunkName: "Settings" */ 'app/pages/preferences')
);
const Profile = loadable(() => import(/* webpackChunkName: "Profile" */ 'app/pages/profile'));
const Terms = loadable(() => import(/* webpackChunkName: "Terms" */ 'app/pages/terms'));
const Security = loadable(() => import(/* webpackChunkName: "Security" */ 'app/pages/security'));
const Privacy = loadable(() => import(/* webpackChunkName: "Privacy" */ 'app/pages/privacy'));
const CookiesPage = loadable(
  () => import(/* webpackChunkName: "CookiesPage" */ 'app/pages/cookies')
);

// const Header = getHeader(styled);
// const DrawerSidebar = getDrawerSidebar(styled);
// const scheme = Layout();
// const SidebarContent = getSidebarContent(styled);
// const CollapseBtn = getCollapseBtn(styled);
// const Content = getContent(styled);
// const Footer = getFooter(styled);

const preload = (component) => {
  component.preload && component.preload();
};

// const CustomCookieConsent = memo(() => {
//   const [visible, setVisible] = useState(() => !Cookies.get('cookieConsent'));
//   const [isManageClicked, setIsManageClicked] = useState(false);

//   useEffect(() => {
//     // Ensure `localStorage` is only accessed in the browser
//     if (typeof window !== 'undefined') {
//       const storedIsManageClicked = JSON.parse(localStorage.getItem('isManageClicked'));
//       if (storedIsManageClicked !== null) {
//         setIsManageClicked(storedIsManageClicked);
//       }
//     }
//   }, []); // Empty dependency array ensures this runs only once

//   useEffect(() => {
//     if (visible && !Cookies.get('cookieConsent')) {
//       setVisible(true);
//     }
//   }, [visible]);

//   useEffect(() => {
//     // Ensure `localStorage` is only accessed in the browser
//     if (typeof window !== 'undefined') {
//       localStorage.setItem('isManageClicked', JSON.stringify(isManageClicked));
//     }
//   }, [isManageClicked]);

//   const handleAccept = () => {
//     Cookies.set('cookieConsent', 'true', { expires: 365 });
//     setVisible(false);
//   };

//   const handleDecline = () => {
//     Cookies.set('cookieConsent', 'false', { expires: 365 });
//     setVisible(false);
//   };

//   const handleManageClick = () => {
//     setIsManageClicked((prev) => !prev);
//   };

//   if (!visible) {
//     return null;
//   }

//   return (
//     <div
//       style={{
//         background: '#f5f5f5',
//         color: 'rgba(0, 0, 0, 0.87)',
//         padding: '20px 24px 24px 24px',
//         borderRadius: '12px',
//         boxShadow:
//           '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
//         maxWidth: '280px',
//         position: 'fixed',
//         bottom: '16px',
//         left: '16px',
//         zIndex: '9999',
//       }}>
//       <h2 style={{ fontSize: '17px', marginBottom: '12px', marginTop: '0px' }}>
//         This website uses cookies
//       </h2>
//       <p style={{ marginBottom: '18px', maxWidth: '234px' }}>
//         {isManageClicked
//           ? 'Technical cookies are needed for functionality. You can choose performance or marketing cookies.'
//           : 'We use cookies to enhance your experience. Accept all or manage which cookies you allow us to use.'}{' '}
//         Read more in our{' '}
//         <a href="/cookies" target="_blank" style={{ color: '#003671', fontSize: '13px' }}>
//           Cookie Policy
//         </a>
//         .
//       </p>

//       {isManageClicked && (
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             gap: '10px',
//             marginBottom: '14px',
//           }}>
//           <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <input
//               type="checkbox"
//               checked
//               disabled
//               style={{
//                 cursor: 'default',
//                 accentColor: '#003671',
//                 width: '16px',
//                 height: '16px',
//               }}
//             />
//             <span style={{ color: '#003671' }}>Technical</span>
//           </label>
//           <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <input
//               type="checkbox"
//               style={{
//                 accentColor: '#003671',
//                 width: '16px',
//                 height: '16px',
//               }}
//             />
//             <span style={{ color: '#003671' }}>Performance</span>
//           </label>
//           <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <input
//               type="checkbox"
//               style={{
//                 accentColor: '#003671',
//                 width: '16px',
//                 height: '16px',
//               }}
//             />
//             <span style={{ color: '#003671' }}>Marketing</span>
//           </label>
//         </div>
//       )}

//       <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//         <button
//           onClick={isManageClicked ? handleDecline : handleManageClick}
//           style={{
//             width: '110px',
//             background: '#f5f5f5',
//             borderRadius: '50px',
//             padding: '10px 24px',
//             fontSize: '12px',
//             fontFamily:
//               "Poppins, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
//             cursor: 'pointer',
//             border: '1px solid #003671',
//             color: '#003671',
//             boxShadow:
//               '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
//             margin: '0 12px 0 0',
//           }}>
//           {isManageClicked ? 'Confirm' : 'Manage'}
//         </button>
//         <button
//           onClick={handleAccept}
//           style={{
//             width: '110px',
//             background: '#003671',
//             borderRadius: '50px',
//             padding: '10px 24px',
//             fontSize: '12px',
//             fontFamily:
//               "Poppins, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
//             cursor: 'pointer',
//             border: '1px solid #003671',
//             color: '#fff',
//             boxShadow:
//               '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
//           }}>
//           {isManageClicked ? 'Accept All' : 'Accept All'}
//         </button>
//       </div>
//     </div>
//   );
// });

const CustomCookieConsent = memo(() => {
  const [visible, setVisible] = useState(() => !Cookies.get('cookieConsent'));
  const [isManageClicked, setIsManageClicked] = useState(false);
  const [performanceConsent, setPerformanceConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);

  useEffect(() => {
    // Ensure `localStorage` is only accessed in the browser
    if (typeof window !== 'undefined') {
      const storedIsManageClicked = JSON.parse(localStorage.getItem('isManageClicked'));
      if (storedIsManageClicked !== null) {
        setIsManageClicked(storedIsManageClicked);
      }
    }
  }, []);

  const updateGTMConsent = (preferences) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'consent_update',
        ...preferences,
      });
    }
  };

  const handleAcceptAll = () => {
    Cookies.set('cookieConsent', 'true', {expires: 365});
    setVisible(false);
    updateGTMConsent({
      analytics_storage: 'granted',
      ad_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
    });
    // ReactGA.initialize('G-DTHTQM9V6V'); // Initialise ReactGA for Analytics
    GA4.reInit(); // Reinitialise ReactGA with updated consent
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'false', {expires: 365});
    setVisible(false);
    updateGTMConsent({
      analytics_storage: 'denied',
      ad_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
    });
    // ReactGA.reset(); // Reset ReactGA for Analytics
  };

  // const handleConfirm = () => {
  //   Cookies.set('cookieConsent', 'true', { expires: 365 });
  //   setVisible(false);
  //   updateGTMConsent({
  //     analytics_storage: performanceConsent ? 'granted' : 'denied',
  //     ad_storage: marketingConsent ? 'granted' : 'denied',
  //     functionality_storage: 'granted',
  //     personalization_storage: marketingConsent ? 'granted' : 'denied',
  //   });
  //   if (performanceConsent || marketingConsent) {
  //     ReactGA.initialize('G-DTHTQM9V6V');
  //   }
  // };
  const handleConfirm = () => {
    Cookies.set('cookieConsent', 'true', {expires: 365});
    setVisible(false);

    updateGTMConsent({
      analytics_storage: 'granted', // Always granted for basic analytics
      ad_storage: marketingConsent ? 'granted' : 'denied',
      functionality_storage: 'granted',
      personalization_storage: marketingConsent ? 'granted' : 'denied',
      performance: performanceConsent,
      marketing: marketingConsent,
    });

    // if (marketingConsent) {
    //   ReactGA.initialize('G-DTHTQM9V6V'); // Additional setup for marketing consent
    // }
    GA4.reInit(); // Reinitialise ReactGA with updated consent
  };

  const handleManageClick = () => {
    setIsManageClicked((prev) => !prev);
  };

  if (!visible) {
    return null;
  }

  return (
    <div
      style={{
        background: '#f5f5f5',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '20px 24px 24px 24px',
        borderRadius: '12px',
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        maxWidth: '280px',
        position: 'fixed',
        bottom: '16px',
        left: '16px',
        zIndex: '9998',
      }}>
      <h2 style={{fontSize: '17px', marginBottom: '12px', marginTop: '0px'}}>
        This website uses cookies
      </h2>
      <p style={{marginBottom: '18px', maxWidth: '234px'}}>
        {isManageClicked
          ? 'Technical cookies are needed for functionality. You can choose performance or marketing cookies.'
          : 'We use cookies to enhance your experience. Accept all or manage which cookies you allow us to use.'}{' '}
        Read more in our{' '}
        <a href="/cookies" target="_blank" style={{color: '#003671', fontSize: '13px'}}>
          Cookie Policy
        </a>
        .
      </p>

      {isManageClicked && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '14px',
          }}>
          <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <input
              type="checkbox"
              checked
              disabled
              style={{
                cursor: 'default',
                accentColor: '#003671',
                width: '16px',
                height: '16px',
              }}
            />
            <span style={{color: '#003671'}}>Technical</span>
          </label>
          <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <input
              type="checkbox"
              checked={performanceConsent}
              onChange={() => setPerformanceConsent((prev) => !prev)}
              style={{
                accentColor: '#003671',
                width: '16px',
                height: '16px',
              }}
            />
            <span style={{color: '#003671'}}>Performance</span>
          </label>
          <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <input
              type="checkbox"
              checked={marketingConsent}
              onChange={() => setMarketingConsent((prev) => !prev)}
              style={{
                accentColor: '#003671',
                width: '16px',
                height: '16px',
              }}
            />
            <span style={{color: '#003671'}}>Marketing</span>
          </label>
        </div>
      )}

      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <button
          onClick={isManageClicked ? handleConfirm : handleManageClick}
          style={{
            width: '110px',
            background: '#f5f5f5',
            borderRadius: '50px',
            padding: '10px 24px',
            fontSize: '12px',
            cursor: 'pointer',
            border: '1px solid #003671',
            color: '#003671',
            margin: '0 12px 0 0',
          }}>
          {isManageClicked ? 'Confirm' : 'Manage'}
        </button>
        <button
          onClick={handleAcceptAll}
          style={{
            width: '110px',
            background: '#003671',
            borderRadius: '50px',
            padding: '10px 24px',
            fontSize: '12px',
            cursor: 'pointer',
            border: '1px solid #003671',
            color: '#fff',
          }}>
          Accept All
        </button>
      </div>
    </div>
  );
});

let theme = createTheme({
  palette: {
    primary: {
      main: '#003671',
    },
    secondary: {
      main: '#f40057',
    },
    background: {
      default: '#fafafa',
    },
    white: '#fff',
    linkedin: '#0966c2',
    twitter: '#00acee',
    facebook: '#3b5998',
    instagram: '#d6249f',
    youtube: '#f70100',
  },
  typography: {
    fontFamily: [
      'Poppins',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      tablet: 1024,
      lg: 1025,
      xl: 1920,
    },
  },
  zIndex: {
    modal: 5100,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.9em',
          fontFamily: [
            'Poppins',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          color: '#003671',
          padding: '8px',
          opacity: '0.8',
          backgroundColor: '#ffffff',
        },
      },
    },
  },
  overrides: {
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          // This targets the `.MuiSvgIcon-colorPrimary` class
          color: '#003671', // Set your desired color
          // Add other CSS properties as needed
        },
      },
      // colorPrimary: {
      //   '&.MuiSvgIcon-colorPrimary': {
      //     color: '#003671',
      //   },
      // },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,.75)',
      },
    },
    MuiFormHelperText: {
      root: {
        MuiError: {
          fontSize: '11px',
        },
      },
    },

    MuiButton: {
      // lineHeight: '1.6',
      root: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      outlined: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      label: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      contained: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MuiLink: {
      root: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MuiLinkUnderlineHover: {
      lineHeight: '1.6',
      root: {
        fontSize: 'inherit',
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MuiBadge: {
      badge: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MUIDataTableBodyCell: {
      lineHeight: '1.6',
      root: {
        fontSize: '14px',
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MUIDataTableHeadCell: {
      lineHeight: '1.6',
      root: {
        fontSize: '14px',
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MuiTablePagination: {
      caption: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
        fontSize: '14px',
      },
    },
    MuiToggleButton: {
      root: {
        fontFamily: [
          'Poppins',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
    MUIRichTextEditor: {
      root: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
      },
      editor: {
        marginBottom: 20,
      },
      editorContainer: {
        marginTop: 20,
        border: '1px solid gray',
        width: '100%',
        textAlign: 'left',
        padding: '8px',
        minHeight: '600px',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.h1 = {
  lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '2rem',
  fontWeight: '200',
  '@media (min-width:600px)': {
    fontSize: '2.2rem',
    fontWeight: '200',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
    fontWeight: '200',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
    fontWeight: '200',
  },
};

theme.typography.h2 = {
  lineHeight: '1',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '1.2rem',
  fontWeight: '200',
  '@media (min-width:600px)': {
    fontSize: '1rem',
    fontWeight: '200',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontWeight: '200',
  },
};

theme.typography.h3 = {
  lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '1.5rem',
  fontWeight: '200',
  '@media (min-width:600px)': {
    fontSize: '3.5rem',
    fontWeight: '200',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
    fontWeight: '200',
  },
};

theme.typography.h4 = {
  lineHeight: '44px',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '24px',
  fontWeight: '400',
  '@media (min-width:600px)': {
    fontSize: '44px',
    fontWeight: '400',
    lineHeight: '66px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '44px',
    fontWeight: '400',
    // lineHeight: '66px',
  },
};

theme.typography.h5 = {
  lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '1.2rem',
  fontWeight: '400',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
    fontWeight: '400',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    fontWeight: '400',
  },
};

theme.typography.h6 = {
  lineHeight: '1',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '1.3rem',
  fontWeight: '600',
  '@media (min-width:600px)': {
    fontSize: '1.3rem',
    fontWeight: '600',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
    fontWeight: '600',
  },
};

theme.typography.subtitle1 = {
  lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  letterSpacing: '0.07rem',
  fontSize: '1rem',
  fontWeight: '500',
  wordSpacing: '0.1rem',
};

theme.typography.subtitle2 = {
  // lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  letterSpacing: '0.07rem',
  fontSize: '0.8rem !important',
  fontWeight: '500',
  wordSpacing: '0.1rem',
};

theme.typography.caption = {
  // lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  letterSpacing: '0.07rem',
  // fontSize: '0.8rem !important',
  // fontWeight: '500',
  // wordSpacing: '0.1rem',
};

theme.typography.body = {
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.9rem',
  },
};

theme.typography.body1 = {
  lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: '0.8rem',
  '@media (min-width:600px)': {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.8rem',
  },
};

theme.typography.body2 = {
  lineHeight: '1.6',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  // fontSize: '3rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

theme.typography.body3 = {
  lineHeight: '1.6',
  fontSize: '18px',
  fontFamily: [
    'Poppins',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  // fontSize: '3rem',
  '@media (min-width:600px)': {
    fontSize: '24px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
};

function App(props) {
  useEffect(() => {
    GA4.init();
  }, []);
  // console.log('APP PROPS', props);
  React.useEffect(() => {
    preload(Welcome);
    preload(Features);
    preload(Product);
    preload(Solutions);
    preload(Technology);
    preload(Inspiration);
    preload(Login);
    preload(Pricing);
    preload(OurStory);
    preload(Support);
    preload(Register);
    preload(Priya);
    preload(James);
    preload(Alison);
    preload(Newsletter);
    // preload(Dashboard);
    preload(Leads);
    preload(People);
    preload(Person);
    preload(Social);
    preload(Emails);
    preload(Profiles);
  }, []);
  function getAuth(auth) {
    // setIsAuthenticated(auth.isAuthenticated);
  }

  function getProfile(profile) {
    // setProfile(profile.status);
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMounted, setIsMounted] = useState(undefined);

  const handleScroll = () => {
    if (typeof window !== 'undefined') {
      setScrollPosition(window.scrollY);
    }
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMounted(window.innerHeight);
      // console.log('scrollPosition', scrollPosition, window.innerHeight);
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const params = useParams();

  // const location = useLocation();

  function Cookies() {
    const [isManageClicked, setIsManageClicked] = useState(false);
    const [visible, setVisible] = useState('byCookieValue'); // Default visibility controlled by cookie value

    const handleManageClick = () => {
      setIsManageClicked(true);
      setVisible('show'); // Show the cookie options when "Manage" is clicked
    };

    const handleDenyClick = () => {
      // Cookies.set('yourCookieName', false); // Replace with the actual cookie name and value for denial
      setVisible('hidden'); // Hide the cookie bar
    };
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CustomCookieConsent />
          {/* <CookieConsent
            expires={99999}
            enableDeclineButton // Ensure the decline button is enabled and visible
            visible={visible}
            onAccept={() => {
              // Cookies.set('yourCookieName', true); // Replace with the actual cookie name and value for acceptance
              setVisible('hidden'); // Hide the cookie bar
            }}
            declineButtonText={isManageClicked ? 'Deny' : 'Manage'}
            buttonText={isManageClicked ? 'Accept' : 'Accept All'}
            style={{
              background: '#f5f5f5',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '20px 24px 24px 24px',
              borderRadius: '12px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              maxWidth: '280px',
              position: 'fixed',
              bottom: '16px',
              right: '16px',
              zIndex: '9999',
              margin: '0 0 14px 18px',
            }}
            contentStyle={{
              margin: '0 0 12px 0',
              fontSize: '10px',
              maxWidth: '234px',
            }}
            buttonStyle={{
              width: '110px',
              background: '#003671',
              borderRadius: '50px',
              padding: '10px 24px',
              fontSize: '12px',
              fontFamily:
                "Poppins, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              cursor: 'pointer',
              border: '1px solid #003671',
              color: '#fff',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              margin: '0',
            }}
            declineButtonStyle={{
              width: '110px',
              background: '#f5f5f5',
              borderRadius: '50px',
              padding: '10px 24px',
              fontSize: '12px',
              fontFamily:
                "Poppins, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              cursor: 'pointer',
              border: '1px solid #003671',
              color: '#003671',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              margin: '0 12px 0 0',
            }}
            declineButtonClasses={isManageClicked ? 'deny-button' : 'manage-button'} // Custom classes for styling if needed
            onDecline={isManageClicked ? handleDenyClick : handleManageClick} // Change functionality based on button click
          >
            <h2 style={{fontSize: '17px', marginBottom: '12px', marginTop: '0px'}}>
              This website uses cookies
            </h2>
            <p style={{marginBottom: '16px', maxWidth: '234px'}}>
              {isManageClicked
                ? 'Technical cookies are needed for functionality. You can choose performance or marketing cookies.'
                : 'We use cookies to enhance your experience. Accept all or manage which cookies you allow us to use.'}{' '}
              Read more in our{' '}
              <a href="/cookies" target="_blank" style={{color: '#003671', fontSize: '10px'}}>
                Cookie Policy
              </a>
              .
            </p>
            {isManageClicked && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginBottom: '8px',
                }}>
                <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <input
                    type="checkbox"
                    checked
                    disabled
                    style={{
                      cursor: 'default',
                      accentColor: '#003671', // This sets the background color for the checkbox when checked
                      width: '16px',
                      height: '16px',
                    }}
                  />
                  <span style={{color: '#003671'}}>Technical</span>
                </label>
                <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <input
                    type="checkbox"
                    checked
                    style={{
                      accentColor: '#003671',
                      width: '16px',
                      height: '16px',
                    }}
                  />
                  <span style={{color: '#003671'}}>Performance</span>
                </label>
                <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <input
                    type="checkbox"
                    checked
                    style={{
                      accentColor: '#003671',
                      width: '16px',
                      height: '16px',
                    }}
                  />
                  <span style={{color: '#003671'}}>Marketing</span>
                </label>
              </div>
            )}
          </CookieConsent> */}
          {/* <CookieConsent
            expires={99999}
            enableDeclineButton
            onDecline={() => {
              alert('You have opted for essential cookies only.');
              Cookies.remove('yourCookieName'); // Replace with actual cookie name
            }}
            declineButtonText="Manage"
            declineButtonStyle={{
              width: '110px',
              background: '#f5f5f5',
              borderRadius: '50px',
              padding: '10px 24px',
              fontSize: '12px',
              fontFamily:
                "Poppins, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              cursor: 'pointer',
              border: '1px solid #003671',
              color: '#003671',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              margin: '0 12px 0 0',
            }}
            declineButtonClick={handleManageClick}
            style={{
              background: '#f5f5f5',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '20px 24px 24px 24px',
              borderRadius: '12px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              maxWidth: '280px',
              position: 'fixed',
              bottom: '16px',
              right: '16px',
              zIndex: '9999',
              margin: '0 0 14px 18px',
            }}
            contentStyle={{
              margin: '0 0 12px 0',
              fontSize: '10px',
              maxWidth: '234px',
            }}
            buttonText={isManageClicked ? 'Accept' : 'Accept All'}
            buttonStyle={{
              width: '110px',
              background: '#003671',
              borderRadius: '50px',
              padding: '10px 24px',
              fontSize: '12px',
              fontFamily:
                "Poppins, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              cursor: 'pointer',
              border: '1px solid #003671',
              color: '#fff',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              margin: '0',
            }}>
            <h2 style={{fontSize: '17px', marginBottom: '12px', marginTop: '0px'}}>
              This website uses cookies
            </h2>
            <p style={{marginBottom: '16px', maxWidth: '234px'}}>
              We use cookies to enhance your experience. Choose which cookies you allow us to use.
              You can read more in our{' '}
              <a href="/cookies" target="_blank" style={{color: '#003671', fontSize: '10px'}}>
                Cookie Policy
              </a>
              .
            </p>
            {isManageClicked && (
              <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <input type="checkbox" checked disabled style={{cursor: 'default'}} />
                  <span>Strictly Necessary</span>
                </label>
                <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <input type="checkbox" />
                  <span>Performance</span>
                </label>
                <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <input type="checkbox" />
                  <span>Marketing</span>
                </label>
              </div>
            )}
          </CookieConsent> */}
          {/* <CookieConsent
            expires={99999}
            enableDeclineButton
            onDecline={() => {
              alert('You have opted for essential cookies only.');
              Cookies.remove('yourCookieName'); // Replace with actual cookie name
            }}
            declineButtonText="Manage"
            declineButtonStyle={{
              width: '110px',
              background: '#f5f5f5',
              borderRadius: '50px',
              padding: '10px 24px',
              fontSize: '12px',
              cursor: 'pointer',
              border: '1px solid #003671',
              color: '#003671',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              margin: '0 12px 0 0',
            }}
            style={{
              background: '#f5f5f5',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '20px 24px 24px 24px',
              borderRadius: '12px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              maxWidth: '280px',
              position: 'fixed',
              bottom: '16px',
              right: '16px',
              zIndex: '9999',
              margin: '0 0 14px 18px',
            }}
            // containerClasses="cookiecontainer"
            contentStyle={{
              margin: '0 0 12px 0',
              fontSize: '10px',
              maxWidth: '234px',
            }}
            buttonText="Accept All"
            buttonStyle={{
              width: '110px',
              background: '#003671',
              borderRadius: '50px',
              padding: '10px 24px',
              fontSize: '12px',
              cursor: 'pointer',
              border: '1px solid #003671',
              color: '#fff',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              margin: '0',
            }}>
            <h2 style={{fontSize: '17px', marginBottom: '12px', marginTop: '0px'}}>This website uses cookies</h2>
            <p style={{marginBottom: '16px', maxWidth: '234px'}}>
              We use cookies to enhance your experience. Choose which cookies you allow us to
              use. You can read more in our{' '}
                <a
                  href="/cookies"
                  target="_blank"
                  style={{ color: '#003671', fontSize: '10px' }}
                >
                  Cookie Policy
                </a>
              .
            </p>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <input type="checkbox" checked disabled style={{cursor: 'default'}} />
                <span>Strictly Necessary</span>
              </label>
              <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <input type="checkbox" />
                <span>Performance</span>
              </label>
              <label style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <input type="checkbox" />
                <span>Marketing</span>
              </label>
            </div>
          </CookieConsent> */}
          {/* <CookieConsent
            expires={99999}
            enableDeclineButton
            onDecline={() => {
              alert('You have opted for essential cookies only.');
              Cookies.remove('yourCookieName'); // Replace with actual cookie name
            }}
            declineButtonText="Essential Only"
            declineButtonStyle={{
              background: '#ffffff',
              color: '#ff0000',
              borderRadius: '50px',
              padding: '8px 16px',
              margin: '12px 10px 16px 0',
              border: '1px solid #ff0000',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              fontSize: '14px',
              cursor: 'pointer',
            }}
            style={{
              background: '#f5f5f5',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '16px',
              borderRadius: '12px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              maxWidth: '264px',
              position: 'fixed',
              left: '16px',
              zIndex: '9999',
            }}
            containerClasses="cookiecontainer"
            contentStyle={{
              margin: '0 0 12px 0',
              maxWidth: '236px',
              fontSize: '10px',
            }}
            buttonText={
              <div style={{padding: '4px', margin: '0px 0px -5px -2px'}}>
                <CheckCircleSharpIcon style={{fontSize: '36px', color: '#003671'}} />
              </div>
            }
            buttonStyle={{
              background: '#f5f5f5',
              borderRadius: '50px',
              padding: '8px 16px',
              margin: '12px 0 16px 0',
              fontSize: '14px',
              cursor: 'pointer',
              border: 'none',
              color: '#003671',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            }}>
            This website uses cookies to enhance the user experience. By clicking "Accept", you
            agree to the storing of cookies on your device to enhance site navigation and analyse
            site usage. By clicking "Essential Only", only essential cookies will be stored.
          </CookieConsent> */}
          {/* <CookieConsent
            expires={99999}
            // buttonClasses="containedPrimary"
            style={{
              background: '#f5f5f5',
              color: 'rgba(0, 0, 0, 0.87)',
              width: '218px',
              height: '56px',
              margin: '0px 0px 16px 16px',
              borderRadius: '50px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            }}
            containerClasses="cookiecontainer"
            contentStyle={{
              margin: '5px 6px 6px 20px',
              maxWidth: '180px',
            }}
            buttonText={
              // <IconButton
              //   color="primary"
              //   type="submit"
              //   aria-label="Submit Form"
              //   style={{padding: '4px', margin: '0px 0px -1px -2px'}}
              //   size="large">
              //   <CheckCircleSharpIcon style={{fontSize: '36px', color: '#003671'}} />
              // </IconButton>
              <div style={{ padding: '4px', margin: '0px 0px -5px -2px' }}>
                <CheckCircleSharpIcon
                  style={{ fontSize: '36px', color: '#003671' }}
                />
              </div>
            }
            buttonStyle={{
              position: 'fixed',
              left: '172px',
              bottom: '7px',
              background: '#f5f5f5',
              borderRadius: '50px',
              padding: '0px',
              margin: '12px 14px 16px 17px',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  lineHeight: '11px',
                  paddingTop: '4px',
                  textAlign: 'left',
                }}
              >
                <span style={{ fontSize: '10px' }}>
                  You agree to accept cookies. See our
                </span>{' '}
                <a
                  href="/cookies"
                  target="_blank"
                  style={{ color: '#003671', fontSize: '10px' }}
                >
                  Cookie Policy
                </a>{' '}
                <span style={{ fontSize: '10px' }}>for more.</span>
              </Grid>
            </Grid>
          </CookieConsent> */}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const UserProfile = () => {
    const user = useSelector((state) => state.PROFILE_REDUCER.Profile);
    return user;
  };
  const Auth = () => {
    const user = useSelector((state) => state.AUTH_REDUCER.Auth);
    return user;
  };

  const profile = UserProfile();
  const auth = Auth();
  const {isAuthenticated} = auth;

  const location = useLocation();

  // const {isAuthenticated} = false;

  // console.log('isAuthenticated', isAuthenticated)

  // useEffect(() => {
  //   if (auth.token !== null) {
  //     console.log('AUTH TOKEN NO LONGER NULL', auth.token);
  //     const DISPATCH_PROFILE = () => {
  //       dispatch(PROFILE_ACTION(auth.token));
  //     };
  //     DISPATCH_PROFILE();
  //   }
  // }, []);

  // let stripePromise;
  // const getStripe = () => {
  //   if (!stripePromise) {
  //     stripePromise = loadStripe(
  //       process.env.NODE_ENV === 'development'
  //         ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
  //         : 'pk_live_51GzJT4EMR9XBqlBhFi0t106M1iid9t04UxzgtK7x2lR0holPKazqqPsbq9QslpZfniPF02P8jIeRZABJnNAgr8ge00UXu7Q1Ur'
  //     );
  //   }
  //   return stripePromise;
  // };
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
        : 'pk_live_51GzJT4EMR9XBqlBhFi0t106M1iid9t04UxzgtK7x2lR0holPKazqqPsbq9QslpZfniPF02P8jIeRZABJnNAgr8ge00UXu7Q1Ur'
    )
  );

  // fetch('/meta.json')
  //   .then((response) => response.json())
  //   .then((meta) => {
  //     const latestVersion = meta.version;
  //     // const currentVersion = global.appVersion;

  //     // const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
  //     if (latestVersion > VERSION) {
  //       console.log(`We have a new version - ${latestVersion}. ${VERSION} Should force refresh`);
  //       if (caches) {
  //         // Service worker cache should be cleared with caches.delete()
  //         caches.keys().then((names) => {
  //           for (const name of names) caches.delete(name);
  //         });
  //       }
  //       // delete browser cache and hard reload
  //       window.location.reload(true);
  //     } else {
  //       console.log(
  //         `You already have the latest version - ${latestVersion}. No cache refresh needed.`
  //       );
  //     }
  //   });

  const isProduction = process.env.NODE_ENV === 'production';
  // console.log('VERSION', VERSION);
  // const location = {
  //   pathname: typeof window !== 'undefined' ? window.location.pathname : props.pathname,
  // //   // search: '?foo=bar',
  // //   // hash: '#my-hash',
  // };
  // console.log('LOCATION HERE', location);
  return (
    <CacheBuster>
      {({loading, isLatestVersion, refreshCacheAndReload}) => {
        //     // if (loading) return null;
        //     // console.log('CACHE BUSTER', isLatestVersion)
        // console.log('Loading:', loading, 'IsLatestVersion:', isLatestVersion);
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
          return null; // Render nothing or a placeholder while waiting for the refresh
        }
        if (loading || isLatestVersion) {
          return (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <DndProvider backend={HTML5Backend}>
                  <CssBaseline />
                  {/* <Root scheme={scheme} theme={theme}> */}
                  <GA4.RouteTracker location={location} />
                  <Root
                    scheme={{
                      header: {
                        config: {
                          xs: {
                            position: 'fixed',
                            height: 56,
                            offsetHeight: 56,
                          },
                          md: {
                            position: 'fixed',
                            height: 56,
                            clipped: true,
                          },
                        },
                      },
                      leftEdgeSidebar: {
                        autoCollapse: 'md',
                        config: {
                          xs: {
                            variant: 'temporary',
                            width: 236,
                            paddingTop: '0px',
                          },
                          md: {
                            variant: 'permanent',
                            width: 236,
                            collapsible: true,
                            collapsedWidth: 64,
                            // headerMagnetEnabled: true,
                            uncollapsedOnHover: true, // in v4, it was `autoExpanded`
                            paddingTop: '0px',
                          },
                        },
                      },
                    }}>
                    <ParallaxProvider>
                      {/* {GA.init() && <GA.RouteTracker />} */}
                      <ScrollToTop />
                      <Header style={{backgroundColor: '#fafafa', zIndex: '1300'}}>
                        <Nav getAuth={getAuth} getProfile={getProfile} />
                      </Header>

                      {!isAuthenticated ? (
                        // <div>
                        <Elements stripe={stripePromise}>
                          <Routes>
                            <Route path="/" element={<Welcome />} />
                            <Route path="/features" element={<Features />} />
                            <Route path="/our-data" element={<Product />} />
                            <Route path="/newsletter" element={<Newsletter />} />
                            <Route path="/2023-UK-report" element={<Report />} />
                            <Route path="/product" element={<Product />}>
                              <Route
                                path="/product/leadsearch-sales-intelligence"
                                element={<Product />}
                              />
                              <Route path="/product/sales-automation" element={<Product />} />
                              <Route path="/product/ai-analysis-and-crm" element={<Product />} />
                              <Route path="/product/connect-email" element={<Product />} />
                            </Route>
                            <Route path="/solutions" element={<Solutions />}>
                              <Route path="/solutions/sales" element={<Solutions />} />
                              <Route path="/solutions/marketing" element={<Solutions />} />
                              <Route path="/solutions/founders" element={<Solutions />} />
                            </Route>
                            <Route path="/technology" element={<Technology />} />
                            <Route path="/inspiration" element={<Inspiration />} />
                            <Route path="/our-story" element={<OurStory />} />
                            <Route path="/support" element={<Support />} />
                            <Route
                              path="/insight"
                              element={<Insights insights={props.insights} copy={props.copy} />}>
                              <Route
                                path="/insight/:post"
                                element={<Insights insights={props.insights} copy={props.copy} />}
                              />
                            </Route>
                            <Route
                              path="/insights"
                              element={<Insights insights={props.insights} copy={props.copy} />}>
                              <Route
                                path="/insights/:post"
                                element={<Insights insights={props.insights} copy={props.copy} />}
                              />
                            </Route>
                            <Route path="/priya" element={<Priya />} />
                            <Route path="/james" element={<James />} />
                            <Route path="/alison" element={<Alison />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/free-trial" element={<Register />} />
                            <Route path="/start-free" element={<Register />} />
                            <Route path="/password/forgot/" element={<Forgot />} />
                            <Route path="/password/reset/:token" element={<Reset />} />
                            <Route path="/profile" element={<PrivateRoute />}>
                              <Route element={<Profile />} />
                            </Route>
                            <Route path="/confirm/" element={<Confirm />}>
                              <Route path="/confirm/:token" element={<Confirm />} />
                            </Route>
                            <Route path="/templates" element={<PrivateRoute />} />
                            <Route path="/emails" element={<PrivateRoute />} />
                            {/* <Route path="/compose/" element={<PrivateRoute />} /> */}
                            <Route path="/update/*" element={<Preferences />}>
                              {/* <Route path="/update/:type/:id/:data1/:data2" element={<Preferences />} /> */}
                            </Route>
                            <Route path="/terms" element={<Terms />} />
                            <Route path="/security" element={<Security />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/cookies" element={<CookiesPage />} />
                            <Route path="/notifications" element={<PrivateRoute />} />
                            <Route path="/companies" element={<PrivateRoute />} />
                            <Route path="/leads" element={<PrivateRoute />} />
                            <Route path="/social" element={<PrivateRoute />} />
                            <Route path="/lead" element={<PrivateRoute />} />
                            <Route path="/ul" element={<PrivateRoute />} />
                            <Route path="/profiles" element={<PrivateRoute />} />
                            <Route path="/leadsearch/*" element={<PrivateRoute />} />
                            <Route path="/settings/*" element={<PrivateRoute />} />
                            <Route path="/campaigns/*" element={<PrivateRoute />} />
                            <Route path="/upgrade" element={<PrivateRoute />} />
                            {/* <Route path="/renew" element={<PrivateRoute />} /> */}
                            <Route path="/pricing/" element={<Pricing />}>
                              <Route
                                path="/pricing/:page/:sub_type/:cycle/:sub_price/"
                                element={<Pricing />}
                              />
                            </Route>
                            <Route path="/verifypayment" element={<VerifyingPayment />} />
                            <Route path="/leadsearch" element={<Leadsearch />} />
                            <Route
                              path="/uk"
                              element={<Lead company={props.company} isBot={props.isBot} />}>
                              <Route
                                path="/uk"
                                element={<Lead company={props.company} isBot={props.isBot} />}
                              />
                              <Route
                                path="/uk/:token"
                                element={<Lead company={props.company} isBot={props.isBot} />}
                              />
                              <Route
                                path="/uk/:token/:cn"
                                element={<Lead company={props.company} isBot={props.isBot} />}
                              />
                            </Route>
                            {/* Make a 404 page for the below */}
                            <Route path="*" element={<Welcome params={params} />} />
                          </Routes>
                          {/* <CopyrightFooter /> */}
                          <Cookies />
                        </Elements>
                      ) : profile.status === 'confirm' && profile.status !== undefined ? (
                        <div>
                          <Routes>
                            <Route
                              path="*"
                              element={
                                <PrivateRoute>
                                  <Preconfirm />
                                </PrivateRoute>
                              }
                            />
                          </Routes>
                          <Copyright />
                        </div>
                      ) : profile.status === 'complete' && profile.status !== undefined ? (
                        <div>
                          <Routes>
                            <Route
                              path="*"
                              element={
                                <PrivateRoute>
                                  <CompleteSignup />
                                </PrivateRoute>
                              }
                            />
                          </Routes>
                          <Copyright />
                        </div>
                      ) : (
                        <div>
                          <EdgeSidebar anchor="left" className="sidebarcontent">
                            <Hidden mdDown>
                              <SidebarContent className="sidebarcontent">
                                <SideNavBar activate={profile.level} />
                              </SidebarContent>
                            </Hidden>
                            <Hidden mdUp>
                              <SidebarContent className="sidebarcontentmobile">
                                <SideNavBar activate={profile.level} />
                              </SidebarContent>
                            </Hidden>
                            <EdgeTrigger
                              style={{overflow: 'hidden'}}
                              target={{anchor: 'left', field: 'collapsed'}}>
                              {(collapsed, setCollapsed) => (
                                <Button
                                  style={{
                                    minHeight: '40px',
                                    // borderRadius: '24px',
                                    borderTop: '1px solid',
                                    textAlign: 'center',
                                    borderColor: 'rgba(0, 0, 0, 0.04)',
                                    // borderRadius: '0',
                                    padding: '6px 8px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    bottom: '0',
                                    fontSize: '0.875rem',
                                    minWidth: '100%',
                                    boxSizing: 'border-box',
                                    transition:
                                      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                    fontWeight: '500',
                                    lineHeight: '1.75',
                                    letterSpacing: '0.02857em',
                                    textTransform: 'uppercase',
                                    backgroundColor: '#fafafa',
                                  }}
                                  onClick={() => setCollapsed(!collapsed)}>
                                  {collapsed ? (
                                    <ArrowForwardIosSharpIcon fontSize="small" />
                                  ) : (
                                    <ArrowBackIosNewSharpIcon fontSize="small" />
                                  )}
                                </Button>
                              )}
                            </EdgeTrigger>
                            {/* </Hidden> */}
                          </EdgeSidebar>
                          <Content>
                            <Elements stripe={stripePromise}>
                              <Routes>
                                <Route path="/" element={<Leads />} />
                                <Route
                                  path="/login"
                                  element={
                                    // location.state.lastpage ? (
                                    //   <Navigate to={location.state.lastpage} replace />
                                    // ) : (
                                    // <Navigate to="/leads" replace />
                                    <LoginRedirect />
                                    // )
                                  }
                                />
                                <Route
                                  path="/register"
                                  element={<Navigate to="/leadsearch/?m=confirm-email" />}
                                />
                                <Route path="/newsletter" element={<Newsletter />} />
                                <Route path="/password/forgot/" element={<Forgot />} />
                                <Route path="/password/reset/:token" element={<PrivateRoute />}>
                                  <Route path="/password/reset/:token" element={<Reset />} />
                                </Route>
                                <Route path="/verifypayment" element={<VerifyingPayment />} />
                                <Route path="/success/*" element={<Success />} />
                                <Route path="/preconfirm/*" element={<Preconfirm />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/security" element={<Security />} />
                                <Route path="/cookies" element={<CookiesPage />} />
                                <Route path="/our-story" element={<OurStory />} />
                                <Route path="/support" element={<Support />} />
                                <Route path="/product" element={<Leads />} />
                                <Route
                                  path="/insight"
                                  element={
                                    <Insights insights={props.insights} copy={props.copy} />
                                  }>
                                  <Route
                                    path="/insight/:post"
                                    element={
                                      <Insights insights={props.insights} copy={props.copy} />
                                    }
                                  />
                                </Route>
                                <Route
                                  path="/insights"
                                  element={
                                    <Insights insights={props.insights} copy={props.copy} />
                                  }>
                                  <Route
                                    path="/insights/:post"
                                    element={
                                      <Insights insights={props.insights} copy={props.copy} />
                                    }
                                  />
                                </Route>
                                <Route
                                  path="/profile"
                                  element={
                                    <PrivateRoute>
                                      <Profile />
                                    </PrivateRoute>
                                  }
                                />
                                {/* <Route element={<Profile/>} />
                      </Route> */}
                                {/* <Route
                                path="/profiles"
                                element={
                                  // <PrivateRoute>
                                  <Profiles />
                                  // </PrivateRoute>
                                }>
                                <Route
                                  path="/profiles/:action"
                                  element={
                                    <PrivateRoute>
                                      <Profiles />
                                    </PrivateRoute>
                                  }
                                />
                                <Route
                                  path="/profiles/:token/:name"
                                  element={
                                    <PrivateRoute>
                                      <Profiles />
                                    </PrivateRoute>
                                  }
                                />
                              </Route> */}
                                {/* <Route path="/confirm/:token" element={<PrivateRoute />}>
                          <Route path="/confirm/:token" element={<Confirm />} />
                        </Route> */}
                                <Route path="/confirm" element={<Confirm />}>
                                  <Route path="/confirm/:token" element={<Confirm />} />
                                </Route>
                                {/* <Route
                                path="/confirm/:token"
                                element={
                                  <PrivateRoute>
                                    <Confirm />
                                  </PrivateRoute>
                                }
                              /> */}
                                {/* <Route path="/compose/" element={<Compose />}>
                                  <Route
                                    path="/compose/:email/:lead"
                                    element={
                                      <PrivateRoute>
                                        <Compose />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route> */}
                                {/* <Route
                                  path="/compose/new"
                                  element={
                                    <PrivateRoute>
                                      <Compose />
                                    </PrivateRoute>
                                  }
                                /> */}
                                <Route
                                  path="/compose"
                                  element={
                                    <PrivateRoute>
                                      <Compose />
                                    </PrivateRoute>
                                  }>
                                  <Route path="/compose/new" element={<Compose />} />
                                </Route>
                                <Route
                                  path="/companies"
                                  element={
                                    <PrivateRoute>
                                      <Leads />
                                    </PrivateRoute>
                                  }>
                                  <Route path="/companies/:profile" element={<Leads />} />
                                </Route>
                                <Route
                                  path="/leads"
                                  element={
                                    <PrivateRoute>
                                      <Leads />
                                    </PrivateRoute>
                                  }>
                                  <Route path="/leads/:profile" element={<Leads />} />
                                </Route>
                                <Route
                                  path="/people"
                                  element={
                                    <PrivateRoute>
                                      <People />
                                    </PrivateRoute>
                                  }>
                                  <Route path="/people/:profile" element={<People />} />
                                </Route>
                                <Route path="/person" element={<Person />}>
                                  <Route
                                    path="/person"
                                    element={
                                      <PrivateRoute>
                                        <Person />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/person/:token"
                                    element={
                                      <PrivateRoute>
                                        <Person />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/person/:token/:name"
                                    element={
                                      <PrivateRoute>
                                        <Person />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route
                                  path="/emails/view/:email"
                                  element={
                                    <PrivateRoute>
                                      <View />
                                    </PrivateRoute>
                                  }
                                />
                                <Route path="/emails" element={<Emails />}>
                                  {/* <Route
                              path="/emails/view/:email"
                              element={<View />}
                            /> */}
                                  <Route
                                    path="/emails/:folder/:pagination"
                                    element={
                                      <PrivateRoute>
                                        <View />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/emails/sent"
                                    element={
                                      <PrivateRoute>
                                        <Emails />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/emails/inbox"
                                    element={
                                      <PrivateRoute>
                                        <Emails />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>

                                <Route path="/lead" element={<Lead />}>
                                  <Route
                                    path="/lead"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/lead/:token"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route path="/uk" element={<Lead />}>
                                  <Route
                                    path="/uk"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/uk/:token"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/uk/:token/:cn"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route path="/ul" element={<Lead />}>
                                  <Route
                                    path="/ul"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/ul/:token"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/ul/:token/:cn"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route path="/:countryCode" element={<Lead />}>
                                  <Route
                                    path="/:countryCode"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/:countryCode/:token"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/:countryCode/:token/:cn"
                                    element={
                                      <PrivateRoute>
                                        <Lead />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route path="/update/*" element={<Preferences />}>
                                  {/* <Route
                              path="/update/:type/:id/:data1/:data2"
                              element={<Preferences />}
                            /> */}
                                </Route>
                                {/* <Route
                          path="/update/:type?/:id?/:data1?/:data2?"
                          element={<Preferences />}
                        /> */}
                                <Route path="/notifications" element={<Notifications />}>
                                  <Route
                                    path="/notifications"
                                    element={
                                      <PrivateRoute>
                                        <Notifications />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                {/* <Route path="/dashboard/" element={<Dashboard />}>
                          <Route
                            path="/dashboard/:message"
                            element={
                              <PrivateRoute>
                                <Dashboard />
                              </PrivateRoute>
                            }
                          />
                        </Route> */}
                                {/* <Route path="/quarters" element={<PrivateRoute/>}> */}
                                <Route path="/quarters" element={<Quarters />}>
                                  {/* </Route> */}
                                  <Route
                                    path="/quarters"
                                    element={
                                      <PrivateRoute>
                                        <Quarters />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route path="/quarters/livechat" element={<Livechat />}>
                                  {/* </Route> */}
                                  <Route
                                    path="/quarters/livechat"
                                    element={
                                      <PrivateRoute>
                                        <Livechat />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>

                                {/* <Route path="/leads/:profile?" element={<PrivateRoute />}>
                          <Route path="/leads/:profile?" element={<Leads />} />
                        </Route> */}
                                <Route
                                  path="/companies"
                                  element={
                                    <PrivateRoute>
                                      <Leads />
                                    </PrivateRoute>
                                  }>
                                  <Route path="/companies/:profile" element={<Leads />} />
                                </Route>
                                <Route
                                  path="/leads"
                                  element={
                                    <PrivateRoute>
                                      <Leads />
                                    </PrivateRoute>
                                  }>
                                  <Route path="/leads/:profile" element={<Leads />} />
                                </Route>
                                {/* <Route path="/leadsearch/" element={<Leadsearch />}>
                          <Route
                            path="/leadsearch/:rows/:page/:country/"
                            element={<Leadsearch />}
                          />
                        </Route> */}
                                <Route
                                  path="/leadsearch/*"
                                  element={
                                    <PrivateRoute>
                                      <Leadsearch />
                                    </PrivateRoute>
                                  }
                                />
                                <Route
                                  path="/actions/*"
                                  element={
                                    <PrivateRoute>
                                      <Actions />
                                    </PrivateRoute>
                                  }
                                />
                                <Route
                                  path="/social/*"
                                  element={
                                    <PrivateRoute>
                                      <Social />
                                    </PrivateRoute>
                                  }
                                />

                                <Route path="/templates" element={<Templates />}>
                                  <Route
                                    path="/templates"
                                    element={
                                      <PrivateRoute>
                                        <Templates />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                <Route
                                  path="/automation"
                                  element={
                                    <PrivateRoute>
                                      <Automation />
                                    </PrivateRoute>
                                  }
                                />
                                <Route
                                  path="/campaigns"
                                  element={
                                    <PrivateRoute>
                                      <Campaigns />
                                    </PrivateRoute>
                                  }
                                />
                                {/* <Route
                            path="/automation/add"
                            element={
                              <PrivateRoute>
                                <Automation />
                              </PrivateRoute>
                            }
                          /> */}
                                {/* </Route> */}
                                {/* <Route path="/upgrade" element={<PrivateRoute/>}>
                        <Route path="/upgrade" element={<Upgrade/>} />
                        </Route> */}
                                <Route path="/upgrade" element={<Upgrade />} />
                                <Route path="/pricing" element={<Pricing />} />
                                <Route path="/verifypayment" element={<VerifyingPayment />} />
                                {/* <Route path="/settings/:tab?" element={<PrivateRoute/>}> */}
                                <Route path="/settings" element={<Settings />}>
                                  <Route
                                    path="/settings/notifications"
                                    element={
                                      <PrivateRoute>
                                        <Settings />
                                      </PrivateRoute>
                                    }
                                  />
                                </Route>
                                {/* </Route> */}
                                {/* Make a 404 page for the below */}
                                <Route path="*" element={<Leads />} />
                              </Routes>
                            </Elements>
                          </Content>
                          <Footer>
                            <Copyright />
                          </Footer>

                          <Cookies />
                        </div>
                      )}
                    </ParallaxProvider>
                    <ToastContainer
                      style={{marginBottom: '56px', fontSize: '14px'}}
                      position="bottom-right"
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      closeOnClick
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      toastClassName="custom-toast"
                    />
                    {isAuthenticated ? (
                      <AddAction profile={profile} />
                    ) : (
                      scrollPosition > isMounted && (
                        <Fab
                          aria-label="scroll-to-top"
                          color="primary"
                          style={{
                            color: '#ffffff',
                            position: 'fixed',
                            bottom: '14px',
                            right: '86px',
                            zIndex: '5000',
                          }}
                          onClick={scrollToTop}>
                          <KeyboardArrowUpIcon
                            style={{
                              transform: 'scaleX(-1)',
                              MozTransform: 'scaleX(-1)',
                              WebkitTransform: 'scaleX(-1)',
                              msTransform: 'scaleX(-1)',
                              fontSize: '34px',
                            }}
                          />
                        </Fab>
                      )
                    )}
                    <Messenger profile={profile} />
                    <LearnPanel profile={profile} location={location} />
                  </Root>
                </DndProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          );
        }
      }}
    </CacheBuster>
  );
}

export default App;
